import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InstagramFeedService } from 'src/app/core/services/api/instagram-feed.service';
import { carouselInstagram } from 'src/app/shared/constants/slides';

import { InstaFeedModel } from '../../models/insta-feed.model';

import * as componentsB4a from '@infrab4a/components-b4a';

@Component({
  selector: 'glam-instagram-carousel',
  templateUrl: './instagram-carousel.component.html',
  styleUrls: ['./instagram-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InstagramCarouselComponent implements OnInit {
  @Input() innerWidth: number;

  instaFeed$: Observable<InstaFeedModel[]>;
  instaFeedStaticsImages = [
    {
      image: 'assets/img/instagram/1-banner-instagram.png',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/useglambeauty/'
    },
    {
      image: 'assets/img/instagram/2-banner-instagram.png',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/useglambeauty/'
    },
    {
      image: 'assets/img/instagram/3-banner-instagram.png',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/useglambeauty/'
    },
    {
      image: 'assets/img/instagram/4-banner-instagram.png',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/useglambeauty/'
    },
    {
      image: 'assets/img/instagram/5-banner-instagram.png',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/useglambeauty/'
    },
    {
      image: 'assets/img/instagram/6-banner-instagram.png',
      alt: 'Imagem instagram',
      permalink: 'https://www.instagram.com/useglambeauty/'
    },
  ];

  carouselTile: componentsB4a.B4aCarouselConfig;

  @ViewChild('myCarousel')
  set _myCarousel(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
      this.carousel = myCarousel;
      setTimeout(() => {
          this.points = myCarousel?.pointNumbers;
      });
  }
  carousel: componentsB4a.B4aCarouselComponent<any>;
  carouselIsFirst = true;
  carouselIsLast = false;
  points: number[] = [];

  constructor(private _instagramFeedService: InstagramFeedService, private cdr: ChangeDetectorRef) {
    this.carouselTile = carouselInstagram;
  }

  ngOnInit(): void {
    this.instaFeed$ = this._instagramFeedService.getFeed().pipe(
      map((feeds) => feeds.filter((data) => data.media_type !== 'VIDEO')),
      map((feeds) =>
        feeds.map((feed) => {
          return {
            ...feed,
            caption:
              feed.caption?.trim() !== ''
                ? `${feed.caption.substring(0, 240)}...`
                : '',
          };
        })
      )
    );
  }

  onCarouselMove(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
    this.carouselIsFirst = myCarousel.isFirst;
    this.carouselIsLast = myCarousel.isLast;
    if (myCarousel.pointNumbers) {
        this.points = myCarousel.pointNumbers;
    } else {
        this.points = [];
    }
  }
}
