import { Component, Inject, OnInit } from '@angular/core';
import {
  Category,
  CategoryRepository,
  Product,
  ProductRepository,
  Shops,
  Where,
} from '@infrab4a/connect';
import { CatalogService } from '@infrab4a/connect-angular';

@Component({
  selector: 'glam-categories-section',
  templateUrl: './categories-section.component.html',
  styleUrls: ['./categories-section.component.scss'],
})
export class CategoriesSectionComponent implements OnInit {
  result: any;
  category: Category;
  productsSkincare: Array<Product>;
  productsBody: Array<Product>;
  productsMake: Array<Product>;
  productsPerfume: Array<Product>;
  productsAccessories: Array<Product>;
  showMoreCategories = false;

  redirectLinks = [
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=0&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-skincare',
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=327.20001220703125&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-corpo',
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=519.2000122070312&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-maquiagem',
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=0&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-perfumaria',
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=200&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-acessórios',
  ];

  constructor(
    private catalogService: CatalogService,
    @Inject('CategoryRepository')
    protected categoryRepository: CategoryRepository,
    @Inject('ProductRepository') private productRepository: ProductRepository
  ) {}

  async ngOnInit(): Promise<void> {
    this.category = await this.categoryRepository.getCategoryBySlug(
      'glambeauty',
      Shops.GLAMSHOP
    );
    console.log(this.category);

    await this.getCategoriesProducts();
  }

  async getCategoriesProducts(): Promise<void> {
    this.productsSkincare = (
      await this.catalogService.fetchProducts({
        category: this.category,
        filters: { customOptions: ['categorias-skincare'] } as any,
        sort: 'news',
        perPage: 5,
      })
    ).products.data;

    this.productsMake = (
      await this.catalogService.fetchProducts({
        category: this.category,
        filters: { customOptions: ['categorias-maquiagem'] } as any,
        sort: 'news',
        perPage: 5,
      })
    ).products.data;
  }

  async getMoreCategoriesProducts(): Promise<void> {
    if (!this.showMoreCategories) {
      this.productsBody = (
        await this.catalogService.fetchProducts({
          category: this.category,
          filters: { customOptions: ['categorias-corpo'] } as any,
          sort: 'news',
          perPage: 5,
        })
      ).products.data;

      this.productsPerfume = (
        await this.catalogService.fetchProducts({
          category: this.category,
          filters: { customOptions: ['categorias-perfumaria'] } as any,
          sort: 'news',
          perPage: 5,
        })
      ).products.data;

      this.productsAccessories = (
        await this.catalogService.fetchProducts({
          category: this.category,
          filters: { customOptions: ['categorias-acessórios'] } as any,
          sort: 'news',
          perPage: 5,
        })
      ).products.data;

      this.showMoreCategories = true;
    } else {
      this.showMoreCategories = false;
    }
  }
}
